import React, { useState } from "react";
import "./App.scss";

const the_url = "https://cloudflare-first-round.pages.dev";

interface Employee {
  name: string;
  department: string;
  salary: number;
  office: string;
  isManager: boolean;
  skills: string[];
}

function App() {
  const [name, set_name] = useState("");
  const [department, set_department] = useState("");
  const [min_salary, set_min_salary] = useState("");
  const [max_salary, set_max_salary] = useState("");
  const [office, set_office] = useState("");
  const [skill, set_skill] = useState("");

  const [employees, set_employees] = useState([]);

  const initial_information = () => (
    <>
      <span className="text">
        Type into any of the criteria fields and hit search to perform a search
        of employees within the <span className="phos">Phosphorus</span>{" "}
        organization.
      </span>
    </>
  );

  const retrieval_error = () => (
    <>
      <span className="text">
        There was an error fetching the data for your search.
      </span>
    </>
  );

  const [popup, set_popup] = useState(true);
  const [popup_message, set_popup_message] = useState(initial_information);

  async function fetchData() {
    try {
      const res = await fetch(`${the_url}/employee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name === "" ? undefined : name,
          department: department === "" ? undefined : department,
          minSalary:
            min_salary === "" ? undefined : Number.parseInt(min_salary),
          maxSalary:
            max_salary === "" ? undefined : Number.parseInt(max_salary),
          office: office === "" ? undefined : office,
          skill: skill === "" ? undefined : skill,
        }),
      });

      const data = await res.json();
      set_employees(data.employees);
    } catch (e) {
      console.log(e);
      set_popup(true);
      set_popup_message(retrieval_error);
    }
  }

  return (
    <div className="App">
      {popup ? (
        <div className="popup">
          {popup_message}
          <span className="search" onClick={() => set_popup(false)}>
            Close
          </span>
        </div>
      ) : null}
      <div className="topbar">
        <div className="background-container">
          <div className="container">
            <span className="org-title">
              Phosphorus<span className="inc">Inc.</span>
            </span>
          </div>
        </div>
      </div>
      <div className="dashboard">
        <div className="criteria-bar">
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Name"
            onChange={(e) => set_name(e.target.value)}
          />
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Department"
            onChange={(e) => set_department(e.target.value)}
          />
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Min. Salary"
            onChange={(e) => set_min_salary(e.target.value)}
          />
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Max. Salary"
            onChange={(e) => set_max_salary(e.target.value)}
          />
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Office"
            onChange={(e) => set_office(e.target.value)}
          />
          <input
            className="input-criteria"
            autoCorrect="off"
            placeholder="Skill"
            onChange={(e) => set_skill(e.target.value)}
          />
          <span className="search" onClick={fetchData}>
            Search
          </span>
        </div>
        <div className="employees">
          <div className="graph">
            <div>Name</div>
            <div>Department</div>
            <div>Salary</div>
            <div>Office</div>
            <div>Role</div>
            <div>Skills</div>
          </div>
          {employees.map((employee: Employee) => {
            return (
              <div className="employee">
                <div className="employee-name">{employee.name}</div>
                <div className="employee-department">{employee.department}</div>
                <div className="employee-salary">${employee.salary}</div>
                <div className="employee-office">{employee.office}</div>
                {employee.isManager ? (
                  <div className="employee-manager">Manager</div>
                ) : (
                  <div className="employee-manager">Employee</div>
                )}
                <div className="employee-skills">{employee.skills.length}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
